import "./ride.css";
import moment from "moment";
import { message, Modal } from "antd";
import Navbar from "../Components/Navbar";
import { pipViewDate } from "../utils/pip";
import from_to from "../Images/from_to.png";
import ellipse from "../Images/Ellipse_1.png";
import girl_png from "../Images/girl_png.png";
import CarLoader from "../Components/carLoader";
import DatePicker from "react-multi-date-picker";
import React, { useEffect, useState } from "react";
import from_to_red from "../Images/from_to_red.png";
import { useDispatch, useSelector } from "react-redux";
import { setActiveRide } from "../redux/reducers/rideReducer";
import {
  asignRide,
  fetchRides,
  getCSVFile,
  fetchDriverList,
  updateRideStatus,
  updateDriverOfRide,
  fetchActiveRideDetail,
  sendNotificationToDriver,
} from "../redux/action/rideActions";

const RideTabs = () => {
  const [status, setStatus] = useState("");
  const [values, setValues] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const startDate = values.length > 0 ? values[0]?.toDate() : null;
  const endDate = values.length > 1 ? values[1]?.toDate() : null;

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [selectedOption, setSelectedOption] = useState("today");
  const { rides, driverList, activeRide, isLoading } = useSelector(
    (state) => state?.rideReducer
  );

  const tabs = [
    { label: "All Rides", value: "All" },
    { label: "Accepted", value: "Accept" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Pending", value: "Pending" },
    { label: "Scheduled", value: "Schedule" },
  ];

  // Filter function according to the tab :
  const filterRides = (rides, tab) => {
    switch (tab) {
      case "All":
        return rides;
      case "Accept":
        return rides?.filter(
          (item) =>
            item?.status === "Accepted" && item?.scheduledStatus !== "Scheduled"
        );
      case "Completed":
        return rides?.filter((item) => item?.status === "Completed");
      case "Cancelled":
        return rides?.filter((item) => item?.status === "Cancelled");
      case "Pending":
        return rides?.filter(
          (item) =>
            item?.status === "Pending" && item?.scheduledStatus !== "Scheduled"
        );
      case "Schedule":
        return rides?.filter((item) => {
          return (
            (item?.status === "Pending" || item?.status === "Accepted") &&
            item?.scheduledStatus === "Scheduled"
          );
        });
      default:
        return rides;
    }
  };

  // Fitlered rides :
  const filteredRides = filterRides(
    rides?.filter((item) =>
      search
        ? item?.driver_data?.name?.toLowerCase().includes(search?.toLowerCase())
        : item
    ),
    activeTab
  );

  // Fetch rides on component load :
  useEffect(() => {
    dispatch(fetchRides()).then((res) => {
      if (res?.payload?.data?.length > 0) {
        const tab = localStorage.getItem("activeTab");
        console.log({ tab: tab || "All" });
        setActiveTab(tab || "All");
      }
    });
    getDrivers();
  }, [dispatch]);

  // Get the ride detail on every tab change :
  useEffect(() => {
    if (filteredRides?.length > 0) {
      dispatch(
        fetchActiveRideDetail({
          payload: {
            user_id: `${filteredRides[0]?.id}`,
          },
        })
      );
    }

    localStorage.setItem("activeTab", activeTab);
    dispatch(setActiveRide(filteredRides[0]));
  }, [activeTab]);

  useEffect(() => {
    if (activeRide) {
      if (activeRide?.driver_status === 1) {
        setStatus("Accept");
      } else if (activeRide?.Ride_status === 2) {
        setStatus("Completed");
      } else if (activeRide?.driver_status === 2) {
        setStatus("Cancelled");
      } else if (activeRide?.driver_status === 0) {
        setStatus("Pending");
      }
    }
  }, [activeRide]);

  // get-csv data from api
  const handleDownloadCSV = () => {
    const callback = (response) => {
      console.log({ response });
      if (response?.success) {
        onHandleDownloadCsv(response?.data);
      } else {
        message.error(response?.message);
      }
    };

    if (selectedOption == "custom") {
      if (startDate && endDate) {
        dispatch(
          getCSVFile({
            payload: {
              startDate: moment(startDate).format("DD/MM/YYYY"),
              endDate: moment(endDate).format("DD/MM/YYYY"),
              filterType: selectedOption,
            },
            callback,
          })
        );
      } else {
        return message.error("Please select date ranges");
      }
    } else {
      dispatch(
        getCSVFile({ payload: { filterType: selectedOption }, callback })
      );
    }
  };

  // fetch driver list
  const getDrivers = () => {
    dispatch(fetchDriverList());
  };

  // Assgin scheduled ride to another driver :
  const AssignToDriver = (driver, ride) => {
    const data = {
      driver_id: driver?.id,
      booking_id: ride?.id,
      user_id: ride?.user_id,
      name: ride?.name ?? "",
    };

    const callback = (res) => {
      if (res?.success) {
        localStorage.setItem("activeTab", activeTab);
        dispatch(fetchRides());
      }
    };

    dispatch(asignRide({ payload: data, callback }));
  };

  // updateRide to update the status
  const UpdateRides = (id) => {
    let isActive;
    if (status == "Pending") isActive = 0;
    if (status == "Accept") isActive = 1;
    else if (status == "Completed") isActive = 3;
    else if (status == "Cancelled") isActive = 2;

    const data = {
      user_id: `${id}`,
      driver_status: `${isActive}`,
    };

    const callback = (response) => {
      if (response?.success) {
        dispatch(fetchRides());
      }
    };

    dispatch(updateRideStatus({ payload: data, callback }));
  };

  const getStatus = (ride) => {
    switch (ride?.status) {
      case ride?.status === "Accepted" && ride?.scheduledStatus !== "Scheduled":
        return "Accept";
      case ride?.status === "Completed":
        return "Completed";
      case ride?.status === "Cancelled":
        return "Cancelled";
      case ride?.status === "Pending" && ride?.scheduledStatus !== "Scheduled":
        return "Pending";
      case (ride?.status === "Pending" || ride?.status === "Accepted") &&
        ride?.scheduledStatus === "Scheduled":
        return "Scheduled";

      default:
        return "N/A";
    }
  };

  // download csv
  const onHandleDownloadCsv = (apiData) => {
    if (apiData?.length == 0) return;

    const csv_arr = [];
    for (var i = 0; i < apiData?.length; i++) {
      const ride = apiData[i];
      csv_arr.push({
        "S. No.": i + 1,
        "Ride ID": ride?.id,
        "User Name": ride?.name,
        "Driver Name": ride?.driver_data?.name || "N/A",
        "Phone No.": ride?.phone_no || "N/A",
        "Date of Booking": pipViewDate(ride?.dateOfBooking), // Ensure pipViewDate handles formatting
        Time: ride?.time,
        "Start Location": ride?.start,
        "Start Address": ride?.start_2,
        "Destination Address": ride?.destination_1,
        "Ride Status": getStatus(ride),
      });
    }
    const data = csv_arr;
    const csv = convertArrayToCSV(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `Ride Data.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // convert array to csv string
  const convertArrayToCSV = (arr) => {
    if (arr?.length != 0) {
      const headers = Object.keys(arr[0]);
      const rows = arr.map((obj) =>
        headers
          .map((header) => `"${String(obj[header]).replace(/"/g, '""')}"`) // Wrap each value in quotes and escape existing quotes
          .join(",")
      );
      return [headers.map((header) => `"${header}"`).join(","), ...rows].join(
        "\n"
      ); // Quote headers and data values
    } else {
      message.error("Record not found!");
    }
  };

  const updateDriver = (val) => {
    const data = {
      booking_id: activeRide?.id,
      driver_id: val,
    };

    const callback = (res) => {
      if (res?.success) {
        dispatch(fetchRides());
        console.log({ res });
        SendNotification(
          res?.booking_info[0]?.user_id,
          res?.booking_info[0]?.driver_id
        );
        setIsEditModalOpen(false);
      }
    };

    dispatch(updateDriverOfRide({ payload: data, callback }));
  };

  // Send notifications
  const SendNotification = (userId, driverId) => {
    const data = {
      sender_id: `${userId}`,
      reciver_id: `${driverId}`,
      notification_type: "new_ride",
      reciver_type: "Driver",
    };
    dispatch(sendNotificationToDriver({ payload: data }));
  };
  // Rides table row
  const RideCard = ({ item }) => {
    return (
      <div
        className={`ride-item ${
          activeRide?.id === item?.id
            ? "ct_active_ride_card ct_card_active"
            : "ct_active_ride_card"
        }`}
      >
        <div
          className="d-flex align-items-center flex-wrap gap-3 gap-md-5"
          // onClick={() => dispatch(setActiveRide(item))}
          onClick={() => {
            dispatch(
              fetchActiveRideDetail({
                payload: {
                  user_id: `${item?.id}`,
                },
              })
            );
          }}
        >
          <div className="d-flex align-items-center flex-wrap gap-3">
            <div className="ct_user_img_circle" style={{ cursor: "pointer" }}>
              {item?.driver_data?.profile_image != "" &&
              item?.driver_data?.profile_image != "0" ? (
                <img src={item?.driver_data?.profile_image} />
              ) : (
                <img src={ellipse} />
              )}
            </div>
            <div className="d-flex align-items-center gap-4">
              <div className="ct_user_info_name" style={{ cursor: "pointer" }}>
                <h5>{item?.driver_data?.name}</h5>
                <small>{item?.driver_data?.car_model}</small>
              </div>
            </div>
          </div>

          <div className="ct_rides_date" style={{ cursor: "pointer" }}>
            <span className="mb-0">{item?.dateOfBooking}</span>
            <span className="mb-0">{item?.time}</span>
          </div>
        </div>

        <div className="d-flex align-items-center gap-3 flex-wrap justify-content-center">
          {item?.scheduledStatus == "Scheduled" && (
            <div>
              <select
                className="form-control"
                onChange={(e) =>
                  AssignToDriver(JSON.parse(e.target.value), item)
                }
              >
                <option value="" disabled selected>
                  Assign To
                </option>

                {driverList?.map((driver) => (
                  <option value={JSON.stringify(driver)}>{driver?.name}</option>
                ))}
              </select>
            </div>
          )}
          <div className="ct_active_link">
            {item?.driver_status == 0 &&
              item?.Ride_status != 2 &&
              item?.scheduledStatus != "Scheduled" && (
                <a className="ct_active_link">Pending</a>
              )}
            {item?.driver_status == 1 &&
              item?.Ride_status != 2 &&
              item?.scheduledStatus != "Scheduled" && (
                <a className="ct_active_link">Accepted</a>
              )}
            {item?.driver_status == 2 &&
              item?.Ride_status != 2 &&
              item?.scheduledStatus != "Scheduled" && (
                <a className="ct_active_link">Cancelled</a>
              )}
            {item?.Ride_status == 2 && item?.scheduledStatus != "Scheduled" && (
              <a className="ct_active_link">Completed</a>
            )}
            {item?.scheduledStatus == "Scheduled" && (
              <a className="ct_active_link">Scheduled</a>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Loader
  if (isLoading) {
    return <CarLoader />;
  }

  return (
    <>
      <section className="ct_padd_in_100">
        <Navbar data="rides" />
        <div className="container-fluid ct_margin_top">
          <div className="row">
            {/* Tabs */}
            <div className="col-md-12">
              <div className="cb_setting_tab">
                <ul className="flex-wrap">
                  {tabs.map((tab) => (
                    <li key={tab.value}>
                      <a
                        href="javascript:void(0)"
                        className={activeTab === tab.value ? "active" : ""}
                        onClick={() => setActiveTab(tab.value)}
                      >
                        {tab.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Search Input */}
            <div className="col-lg-6">
              <div className="ct_search_bar">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <div className="ct_choose_rides_dot"></div>
            </div>
          </div>
          <div className="row mt-2">
            {/* Rides List */}
            <div className="col-lg-8 mb-4">
              <div className="ct_active_rides_cnt">
                {/* CSV Filter */}
                <div className="d-flex align-items-center justify-content-between mb-3 mt-3 ct_flex_column_767">
                  <h4 className="mb-0">Rides</h4>
                  {activeTab == "All" && (
                    <div className="d-flex align-items-center gap-3 ct_flex_column_767">
                      <select
                        style={{ height: "40px" }}
                        className="form-control ct_input"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      >
                        <option value={"today"}>Today</option>
                        <option value={"weekly"}>Weekly</option>
                        <option value={"monthly"}>Monthly</option>
                        <option value={"custom"}>Custom Dates</option>
                      </select>
                      {selectedOption === "custom" ? (
                        <div className="ct_date_picker_text_indent">
                          <DatePicker
                            value={values}
                            onChange={(date) => setValues(date)}
                            range
                            maxDate={new Date()}
                            editable={false}
                            placeholder="Select start & end date"
                          />
                        </div>
                      ) : null}

                      <button
                        className="ct_custome_btn py-2 ct_btn_border_10 w-auto ct_w_100_767"
                        style={{ fontSize: "16px", whiteSpace: "nowrap" }}
                        type="button"
                        onClick={() => {
                          handleDownloadCSV();
                        }}
                      >
                        CSV Download
                      </button>
                    </div>
                  )}
                </div>

                {/* ride card */}
                {filteredRides?.length > 0 ? (
                  <div className="ct_activre_rides_all_boxes">
                    {filteredRides?.map((item) => (
                      <RideCard item={item} />
                    ))}
                  </div>
                ) : (
                  <h2 className="ct_fs_24 text-center py-4">No Record Found</h2>
                )}
              </div>
            </div>

            {/* Ride Detail*/}
            <div className="col-lg-4 mb-4">
              <div className="ct_active_rides_cnt">
                <h4 className="text-center ">Ride Details</h4>
              </div>

              {filteredRides?.length > 0 && (
                <div className="ct_rides_detail_box">
                  <span className="ct_grey_text">Driver</span>
                  <div className="d-flex justify-content-between flex-wrap mt-3 ct_border_btoom pb-3">
                    {/* Driver details */}
                    <div className="d-flex align-items-center flex-wrap gap-3 ">
                      <div className="ct_user_img_circle">
                        {activeRide?.driver_data?.profile_image != "" &&
                        activeRide?.driver_data?.profile_image != "0" ? (
                          <img src={activeRide?.driver_data?.profile_image} />
                        ) : (
                          <img src={ellipse} />
                        )}
                      </div>
                      <div className="d-flex align-items-center gap-4">
                        <div className="ct_user_info_name">
                          <h5>{activeRide?.driver_data?.name ?? ""}</h5>
                          <small>
                            {activeRide?.driver_data?.car_model ?? ""}
                            <br />
                            {activeRide?.driver_data?.phone_no == "undefined"
                              ? ""
                              : activeRide?.driver_data?.phone_no ?? ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="ct_active_link">
                      {/* {activeRide?.driver_status == 0 &&
                      activeRide?.Ride_status != 2 && (
                        <a className="ct_yellow_text">Pending</a>
                      )}
                    {activeRide?.driver_status == 1 &&
                      activeRide?.Ride_status != 2 && (
                        <a className="ct_yellow_text">Accept</a>
                      )}
                    {activeRide?.driver_status == 2 &&
                      activeRide?.Ride_status != 2 && (
                        <a className="ct_yellow_text">Cancelled</a>
                      )}
                    {activeRide?.Ride_status == 2 && (
                      <a className="ct_yellow_text">Completed</a>
                    )} */}

                      {activeRide?.driver_status == 2 && (
                        <a
                          className="ct_yellow_btn mt-2"
                          onClick={() => {
                            getDrivers();
                            setIsEditModalOpen(true);
                          }}
                        >
                          Reassign Cab
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="ct_border_btoom  py-3">
                    <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
                      <p className="mb-0 ct_grey_text">Date & Time</p>
                      <p>
                        <span className="mb-0">
                          {" "}
                          {activeRide?.dateOfBooking}{" "}
                        </span>
                        <span className="mb-0"> {activeRide?.time} </span>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between  mt-3  ">
                    <div className="d-flex align-items-center gap-4">
                      <div className="ct_user_info_name">
                        <h5>Current Location</h5>
                        <small>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          </svg>
                          {activeRide?.start}
                        </small>
                      </div>
                    </div>
                    <div className="ct_active_link">
                      <img src={from_to} alt="img/from_to.png" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="d-flex align-items-center gap-4">
                      <div className="ct_user_info_name">
                        <h5>Soft Bank Buildings</h5>
                        <small>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-geo-alt"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          </svg>
                          {activeRide?.destination}
                        </small>
                      </div>
                    </div>
                    <div className="ct_active_link">
                      <img src={from_to_red} alt="img/from_to_red.png" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <span className="ct_grey_text mb-3 d-block">User</span>
                    <div className="d-flex align-items-center flex-wrap gap-3">
                      <div className="ct_user_img_circle">
                        {activeRide?.profile_image != "" &&
                        activeRide?.profile_image != "0" ? (
                          <img src={activeRide?.profile_image} />
                        ) : (
                          <img src={girl_png} />
                        )}
                      </div>
                      <div className="d-flex align-items-center gap-4">
                        <div className="ct_user_info_name">
                          <h5>{activeRide?.name}</h5>
                          <small>{activeRide?.phone_no}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ct_slect_option_w">
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="Accept">Accepted</option>
                      <option value="Completed">Completed</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </div>
                  <div className="mt-4">
                    <button
                      className="ct_custome_btn py-2 ct_btn_border_10"
                      onClick={() => UpdateRides(activeRide?.id)}
                    >
                      Update Ride
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Reassign cab model */}
      {/* <div
        className="modal fade"
        id="ct_reassign_modal"
        tabindex="-1"
        aria-labelledby="ct_reassign_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  fill="#FEA92A"
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                >
                 
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="modal-body px-5 pb-5">
              <h5 className="mb-4" onClick={() => console.log("govind")}>
                Choose Cab
              </h5>
              {driverList?.map(
                (item) =>
                  item?.id != activeRide?.driver_data?.id && (
                    <div
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className={
                        driverList[0]?.id == item?.id
                          ? "ct_active_ride_card ct_card_active mb-3"
                          : "ct_active_ride_card mb-3"
                      }
                    >
                      <div className="d-flex align-items-center flex-wrap gap-md-5 gap-3">
                        <div className="d-flex align-items-center flex-wrap gap-3">
                          <div className="ct_user_img_circle">
                            {item?.profile_image != "" &&
                            item?.profile_image != "0" ? (
                              <img src={item?.profile_image} />
                            ) : (
                              <img src={ellipse} />
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{item?.name}</h5>
                              <small>{item?.car_model}</small>
                              <p className="mb-0 ct_fs_141">
                                {item.phone_no == "undefined"
                                  ? ""
                                  : item.phone_no ?? ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="ct_rides_date">
                          <span className="mb-0">
                            {moment(item?.created_at).format("MMM Do YYYY")}
                          </span>
                          <span className="mb-0">
                            {moment(item?.created_at).format("h:mm A")}
                          </span>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        {item?.driver_status == 0 && item?.Ride_status != 2 && (
                          <a className="ct_active_link">Pending</a>
                        )}
                        {item?.driver_status == 1 && item?.Ride_status != 2 && (
                          <a className="ct_active_link ">Accept</a>
                        )}
                        {item?.driver_status == 2 && item?.Ride_status != 2 && (
                          <a className="ct_active_link">Cancelled</a>
                        )}
                        {item?.Ride_status == 2 && (
                          <a className="ct_active_link">Completed</a>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div> */}

      {/* Model Reassign Cab */}
      <Modal
        className="ct_ant_modal_xl"
        title={``}
        centered={true}
        open={isEditModalOpen}
        onCancel={() => {
          setIsEditModalOpen(false);
        }}
        footer={null}
      >
        <div className="mt-4">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body  pb-5 ct_choose_cap_scroll_450 px-3">
                <h5 className="mb-4" onClick={() => console.log("govind")}>
                  Choose Cab
                </h5>
                {driverList?.map(
                  (item, index) =>
                    item?.id != activeRide?.driver_data?.id && (
                      <div
                        onClick={() => updateDriver(item?.id)}
                        className={
                          "ct_active_ride_card mb-3 ct_active_card_hover"
                        }
                      >
                        <div className="d-flex align-items-center flex-wrap gap-md-5 gap-3">
                          <div className="d-flex align-items-center flex-wrap gap-3">
                            <div className="ct_user_img_circle">
                              {item?.profile_image != "" &&
                              item?.profile_image != "0" ? (
                                <img src={item?.profile_image} />
                              ) : (
                                <img src={ellipse} />
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <div className="ct_user_info_name">
                                <h5>{item?.name}</h5>
                                <small>{item?.car_model}</small>
                                <p className="mb-0 ct_fs_141">
                                  {item.phone_no == "undefined"
                                    ? ""
                                    : item.phone_no ?? ""}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="ct_rides_date">
                            <span className="mb-0">
                              {moment(item?.created_at).format("MMM Do YYYY")}
                            </span>
                            <span className="mb-0">
                              {moment(item?.created_at).format("h:mm A")}
                            </span>
                          </div>
                        </div>
                        <div className="ct_active_link">
                          {item?.driver_status == 0 &&
                            item?.Ride_status != 2 && (
                              <a className="ct_active_link">Pending</a>
                            )}
                          {item?.driver_status == 1 &&
                            item?.Ride_status != 2 && (
                              <a className="ct_active_link ">Accept</a>
                            )}
                          {item?.driver_status == 2 &&
                            item?.Ride_status != 2 && (
                              <a className="ct_active_link">Cancelled</a>
                            )}
                          {item?.Ride_status == 2 && (
                            <a className="ct_active_link">Completed</a>
                          )}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RideTabs;
